.kpi-rows-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
}

.kpi-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.kpi-card {
  flex: 1;
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  min-width: calc(25% - 15px);
  position: relative;
  overflow: hidden;
  transition: transform 0.2s, box-shadow 0.2s;
}

.kpi-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.kpi-card h3 {
  margin: 0 0 10px 0;
  font-size: 1rem;
  color: #666;
  font-weight: 500;
}

.kpi-value {
  font-size: 2rem;
  font-weight: bold;
  margin: 10px 0;
  transition: color 0.3s ease;
}

/* Performance colors for KPI values */
.kpi-value.performance-good {
  color: #22c55e; /* Bright green for >66% */
}

.kpi-value.performance-warning {
  color: #f59e0b; /* Bright yellow/orange for 33-66% */
}

.kpi-value.performance-bad {
  color: #ef4444; /* Bright red for <33% */
}

.kpi-target {
  font-size: 0.9rem;
  color: #888;
  margin-top: 5px;
}

.kpi-trend {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2rem;
}

.trend-up {
  color: #4CAF50;
}

.trend-down {
  color: #f44336;
}

/* Progress bar colors */
.kpi-progress {
  width: 100%;
  height: 4px;
  background-color: #f3f4f6;
  border-radius: 2px;
  margin-top: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease, background-color 0.3s ease;
}

.progress-bar.performance-good {
  background-color: #22c55e;
}

.progress-bar.performance-warning {
  background-color: #f59e0b;
}

.progress-bar.performance-bad {
  background-color: #ef4444;
}

/* Responsive design */
@media (max-width: 1200px) {
  .kpi-container {
    flex-wrap: wrap;
    gap: 15px;
  }

  .kpi-card {
    min-width: calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
  }
}

@media (max-width: 768px) {
  .kpi-card {
    min-width: 100%;
    flex: 0 0 100%;
  }
}

.kpi-unit {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
  font-style: italic;
} 